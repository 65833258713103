
import './App.css';
import { useState, useEffect } from 'react';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import Landing from "../Landing/Landing"
import Connect from "../Connect/Connect"
import Meet from "../Meet/Meet"
import Blog from "../Blog/Blog"
import Projects from "../Projects/Projects"
import Tweets from "../Tweets/Tweets"
import Login from "../Login/Login"


function App() {


    const [theme, setTheme] = useState(
      localStorage.getItem('theme') || 'dark'
      );




  let isDarkMode = 0;
  if(theme === 'light')
  {
    isDarkMode = 0;
  } else {
    isDarkMode = 1;
  }


  const toggleTheme = () => {
    if (theme === 'light') {
    setTheme('dark');
    } else {
    setTheme('light');
    }
  };
  useEffect(() => {
    localStorage.setItem('theme', theme);
    document.body.className = theme;
    }, [theme]);
  return (
    <div className={`App ${theme}`}> 
     {/* <button onClick={toggleTheme}>Toggle Theme</button>  */}
    <DarkModeSwitch
      className='react-toggle-dark-mode-animation'
      style={{ marginBottom: '2rem' }}
      checked={isDarkMode}
      onChange={toggleTheme}
      size={120}
    />
    <div>
      <Landing />
      <Connect />
      <Meet />
      <Blog />
      <Projects />
    </div>
    </div>
  );
}

export default App;
