
import './Blog.css';
import { FaBlogger } from "react-icons/fa";

function Blog() {
  return (
    <div className="Blog">
        <h2> Blog </h2>
        <p>
          Some of my thoughts...
        </p>
        <div className="wrap">
          <ul>
            <li className="blog-li">
                <a href="https://blog.sati.sh" rel="noreferrer">
                  <FaBlogger />
                </a>
            </li>                                                                                                                 
          </ul>
        </div>
        <hr />
    </div>
  );
}

export default Blog;
